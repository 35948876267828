var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('template',{slot:"body"},[_c('HeroSection',{attrs:{"image":"sell_banner.jpg","title":_vm.$t('sections.sell.heroSection.title'),"subtitle":_vm.$t('sections.sell.heroSection.subtitle'),"search-placeholder":_vm.$t('sections.sell.heroSection.searchPlaceholder'),"search-button":_vm.$t('sections.sell.heroSection.searchButton'),"keep-button-text":"","value":_vm.heroInput,"is-mobile":_vm.getBreakpoints.xs},on:{"input":function (e) { return _vm.handleInput('heroInput', e); },"click":function($event){return _vm.$router.push({
          name: 'sell-us-process',
          params: {
            address: _vm.heroInput,
          },
        })}}}),_c('SellUsStepsSection'),_c('div',{staticClass:"margin-container sell-us-savings-section"},[_c('InfoPoints',{attrs:{"points":_vm.sellUsPoints}}),_c('SavingsCard',{attrs:{"title":_vm.$t('sections.sell.sellUs.savingsSection.card.title'),"description":_vm.$t('sections.sell.sellUs.savingsSection.card.description'),"placeholder":_vm.$t('sections.sell.sellUs.savingsSection.card.placeholder'),"valid":false,"error-label":_vm.$t('components.input.invalidNumber')}})],1),_c('ReviewsSection',{attrs:{"reviews":_vm.reviews,"authors":_vm.authors,"title":_vm.$t('sections.sell.sellUs.reviewsSection.title'),"description":_vm.$t('sections.sell.sellUs.reviewsSection.subtitle'),"is-mobile":_vm.getBreakpoints.xs}}),_c('ReferralSection',{attrs:{"image":"referral_placeholder.jpg","title":this.$t('sections.homepage.referralSection.title'),"description":this.$t('sections.homepage.referralSection.description'),"primary-button":this.$t('sections.homepage.referralSection.button'),"outline-button":this.$t('components.imageSection.learnMore'),"is-mobile":_vm.getBreakpoints.smAndDown},on:{"primaryClick":function($event){return _vm.$router.push({
          name: 'referral',
          params: {
            scroll: true,
          },
        })},"outlineClick":function($event){return _vm.$router.push({ name: 'referral' })}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }