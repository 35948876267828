<template>
  <layout>
    <template slot="body">
      <HeroSection
        image="sell_banner.jpg"
        :title="$t('sections.sell.heroSection.title')"
        :subtitle="$t('sections.sell.heroSection.subtitle')"
        :search-placeholder="$t('sections.sell.heroSection.searchPlaceholder')"
        :search-button="$t('sections.sell.heroSection.searchButton')"
        keep-button-text
        :value="heroInput"
        @input="(e) => handleInput('heroInput', e)"
        @click="
          $router.push({
            name: 'sell-us-process',
            params: {
              address: heroInput,
            },
          })
        "
        :is-mobile="getBreakpoints.xs"
      />

      <SellUsStepsSection />

      <div class="margin-container sell-us-savings-section">
        <InfoPoints :points="sellUsPoints" />
        <SavingsCard
          :title="$t('sections.sell.sellUs.savingsSection.card.title')"
          :description="
            $t('sections.sell.sellUs.savingsSection.card.description')
          "
          :placeholder="
            $t('sections.sell.sellUs.savingsSection.card.placeholder')
          "
          :valid="false"
          :error-label="$t('components.input.invalidNumber')"
        />
      </div>

      <ReviewsSection
        :reviews="reviews"
        :authors="authors"
        :title="$t('sections.sell.sellUs.reviewsSection.title')"
        :description="$t('sections.sell.sellUs.reviewsSection.subtitle')"
        :is-mobile="getBreakpoints.xs"
      />

      <ReferralSection
        image="referral_placeholder.jpg"
        :title="this.$t('sections.homepage.referralSection.title')"
        :description="this.$t('sections.homepage.referralSection.description')"
        :primary-button="this.$t('sections.homepage.referralSection.button')"
        :outline-button="this.$t('components.imageSection.learnMore')"
        @primaryClick="
          $router.push({
            name: 'referral',
            params: {
              scroll: true,
            },
          })
        "
        @outlineClick="$router.push({ name: 'referral' })"
        :is-mobile="getBreakpoints.smAndDown"
      />
    </template>
  </layout>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  HeroSection,
  ReferralSection,
  ReviewsSection,
  SavingsCard,
  InfoPoints,
} from 'ff-components-lib';
import Layout from '@/components/layout/Layout';

import SellUsStepsSection from '@/components/sections/external/sell/SellUsStepsSection';

export default {
  name: 'SellScreen',
  components: {
    Layout,
    HeroSection,
    SellUsStepsSection,
    SavingsCard,
    ReviewsSection,
    ReferralSection,
    InfoPoints,
  },
  data() {
    return {
      selectionCards: [
        {
          title: this.$t('sections.sell.selectSection.sellUsCard.title'),
          description: this.$t(
            'sections.sell.selectSection.sellUsCard.description',
          ),
          icon: 'sellSelection/sell_us',
        },
        {
          title: this.$t('sections.sell.selectSection.sellWithUsCard.title'),
          description: this.$t(
            'sections.sell.selectSection.sellWithUsCard.description',
          ),
          icon: 'sellSelection/sell_with_us',
        },
        {
          title: this.$t('sections.sell.selectSection.sellAloneCard.title'),
          description: this.$t(
            'sections.sell.selectSection.sellAloneCard.description',
          ),
          icon: 'sellSelection/sell_alone',
        },
      ],
      cardSellType: 0,
      sellType: 0,
      lines: [
        {
          title: 'Taxa de serviço',
          text: '5% a 7%',
        },
        {
          title: 'Taxa de serviço',
          text: '5% a 7%',
        },
        {
          title: 'Taxa de serviço',
          text: '5% a 7%',
        },
        {
          title: 'Taxa de serviço',
          text: '5% a 7%',
        },
      ],
      sellUsPoints: [
        {
          title: this.$t('sections.sell.sellUs.savingsSection.step1.title'),
          description: this.$t(
            'sections.sell.sellUs.savingsSection.step1.description',
          ),
          icon: 'sellPoints/sell_us_point1',
        },
        {
          title: this.$t('sections.sell.sellUs.savingsSection.step2.title'),
          description: this.$t(
            'sections.sell.sellUs.savingsSection.step2.description',
          ),
          icon: 'sellPoints/sell_us_point2',
        },
        {
          title: this.$t('sections.sell.sellUs.savingsSection.step3.title'),
          description: this.$t(
            'sections.sell.sellUs.savingsSection.step3.description',
          ),
          icon: 'sellPoints/sell_us_point3',
        },
      ],
      sellWithUsPoints: [
        {
          title: this.$t('sections.sell.sellWithUs.savingsSection.step1.title'),
          description: this.$t(
            'sections.sell.sellWithUs.savingsSection.step1.description',
          ),
          icon: 'sellPoints/sell_us_point1',
        },
        {
          title: this.$t('sections.sell.sellWithUs.savingsSection.step2.title'),
          description: this.$t(
            'sections.sell.sellWithUs.savingsSection.step2.description',
          ),
          icon: 'sellPoints/sell_us_point1',
        },
        {
          title: this.$t('sections.sell.sellWithUs.savingsSection.step3.title'),
          description: this.$t(
            'sections.sell.sellWithUs.savingsSection.step3.description',
          ),
          icon: 'sellPoints/sell_us_point1',
        },
        {
          title: this.$t('sections.sell.sellWithUs.savingsSection.step4.title'),
          description: this.$t(
            'sections.sell.sellWithUs.savingsSection.step4.description',
          ),
          icon: 'sellPoints/sell_us_point1',
        },
      ],
      agents: [
        {
          photo: 'https://buefy.org/static/img/placeholder-1280x960.png',
          name: 'Filipa Martins',
          description: 'Especialista em Vendas',
          rating: 4,
          button: this.$t('components.agentCard.contactButton'),
        },
        {
          photo: 'https://buefy.org/static/img/placeholder-1280x960.png',
          name: 'Filipa Martins',
          description: 'Especialista em Vendas',
          rating: 4,
          button: this.$t('components.agentCard.contactButton'),
        },
        {
          photo: 'https://buefy.org/static/img/placeholder-1280x960.png',
          name: 'Filipa Martins',
          description: 'Especialista em Vendas',
          rating: 4,
          button: this.$t('components.agentCard.contactButton'),
        },
      ],
      reviews: [],
      authors: [
        {
          // img: 'https://buefy.org/static/img/placeholder-1280x960.png',
          name: 'Maria Torres',
          location: 'Lisboa',
        },
        {
          // img: 'https://buefy.org/static/img/placeholder-1280x960.png',
          name: 'Ricardo Azevedo',
          location: 'Lisboa',
        },
        {
          // img: 'https://buefy.org/static/img/placeholder-1280x960.png',
          name: 'Jorge Machado',
          location: 'Lisboa',
        },
        {
          // img: 'https://buefy.org/static/img/placeholder-1280x960.png',
          name: 'Filipe Rodrigues',
          location: 'Lisboa',
        },
        {
          // img: 'https://buefy.org/static/img/placeholder-1280x960.png',
          name: 'Joana Tomaz',
          location: 'Lisboa',
        },
      ],
      heroInput: '',
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
  created() {
    if (this.$route.query.sellType) {
      this.cardSellType = Number(this.$route.query.sellType);
      this.sellType = Number(this.$route.query.sellType);
    }
    this.$nextTick(() => {
      this.reviews = [
        {
          headerText: this.$t('sections.sell.sellUs.reviewsSection.soldAt', {
            location: 'Lisboa',
          }),
          text:
            'Muito eficientes, pude vender minha casa em tempo record, aliviando o stress financeiro e emocional devido a uma mudança inesperada na minha vida.',
        },
        {
          headerText: this.$t('sections.sell.sellUs.reviewsSection.soldAt', {
            location: 'Lisboa',
          }),
          text:
            'Graças à firstfloor, pude aproveitar uma oportunidade de negócio única. Vendi a minha casa rapidamente e garanti recursos financeiros para investir em outra área.',
        },
        {
          headerText: this.$t('sections.sell.sellUs.reviewsSection.soldAt', {
            location: 'Lisboa',
          }),
          text: 'Equipa muito profissional. Trataram de absolutamente tudo.',
        },
        {
          headerText: this.$t('sections.sell.sellUs.reviewsSection.soldAt', {
            location: 'Lisboa',
          }),
          text:
            'Precisava de vender rápido e não creio que pudesse receber uma proposta melhor. Recomendo.',
        },
        {
          headerText: this.$t('sections.sell.sellUs.reviewsSection.soldAt', {
            location: 'Lisboa',
          }),
          text:
            'Já tinha a casa á venda há algum tempo, muitas visitas mas nenhuma proposta. Acabei por vender á FirstFloor que foram céleres e muito práticos.',
        },
      ];
    });
  },
  methods: {
    handleSellTypeChange(e) {
      this.cardSellType = e;
      this.sellType = null;
      setTimeout(() => {
        this.sellType = e;
      }, 480);
    },
    handleInput(field, value) {
      this[field] = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.sell-selection-section {
  margin-top: 138px;
  margin-bottom: 138px;
  --card-gap: 72px;
}

.steps-section-wrapper {
  margin-top: 138px;
  margin-bottom: 112px;
}

.sell-us-savings-section {
  margin-bottom: 138px;
  display: flex;
  justify-content: center;
}

.reviews-section-wrapper {
  margin-bottom: 0px;
}

.sell-with-us-section-header {
  display: flex;
  justify-content: center;
  margin-bottom: 130px;
}

.comercials-section-header {
  display: flex;
  justify-content: center;
  margin-bottom: 130px;
}

.agents-section {
  margin-bottom: 130px;
}

.sell-alone-section-header {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}

.info-points {
  margin-right: 70px;
}

.sell-with-us-savings-section {
  margin-bottom: -222px;

  display: flex;
  justify-content: center;
  z-index: 10;
  position: relative;

  ::v-deep .list-table--container {
    min-width: unset;
  }

  ::v-deep .list-table--titles {
    gap: 13px;

    .column-title {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      width: 106px;
    }
  }

  ::v-deep .list-table {
    padding: 24px 0px;

    .list-table--lines {
      .line {
        background: linear-gradient(90deg, $tertiary 0%, transparent 100%);

        height: 48px;
        border-radius: 8px;

        padding-left: 34px;

        font-size: 16px;
        line-height: 24px;
      }
    }

    .columns-wrapper {
      gap: 13px;

      .column__item-container {
        width: 106px;
        height: 48px;

        .column__item-text {
          font-size: 16px;
        }
      }

      .column {
        padding: 24px 0px;
        border-radius: 16px;

        .mdi {
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .sell-selection-section {
    margin-top: 28px;
    margin-bottom: 62px;
  }

  .steps-section-wrapper {
    margin-top: 28px;
    margin-bottom: 43px;
  }

  .sell-us-savings-section {
    margin-bottom: 98px;
    flex-direction: column;
    align-items: center;
  }

  .reviews-section-wrapper {
    margin-bottom: 32px;
  }

  .sell-with-us-section-header {
    margin-bottom: 98px;
  }

  .comercials-section-header {
    margin-bottom: 98px;
  }

  .agents-section {
    margin-bottom: 98px;
  }

  .sell-alone-section-header {
    margin-bottom: 0;
  }

  .info-points {
    margin-bottom: 90px;
    margin-right: 0;
  }

  .sell-with-us-savings-section {
    margin-bottom: -144px;

    flex-direction: column;
    align-items: center;

    .savings-card {
      margin-left: -5%;
      margin-right: -5%;
    }

    ::v-deep .list-table--titles {
      gap: 12px;

      .column-title {
        font-size: 10px;
        width: 73px;
      }
    }

    ::v-deep .list-table {
      padding: 16px 0px;

      .list-table--lines {
        .line {
          height: 32px;
          padding-left: 12px;
          font-size: 12px;
        }
      }

      .columns-wrapper {
        gap: 12px;

        .column__item-container {
          width: 73px;
          height: 32px;

          .column__item-text {
            font-size: 12px;
            line-height: 15px;
          }
        }

        .column {
          padding: 16px 0px;

          .mdi {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.section-fade-enter-active,
.section-fade-leave-active {
  transition: all 0.5s;
}
.section-fade-enter,
.section-fade-leave-to {
  opacity: 0;
}
</style>
