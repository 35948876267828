<template>
  <div class="steps-section-wrapper">
    <div class="margin-container d-flex justify-content-center">
      <SectionHeader
        :title="$t('sections.sell.sellUs.title')"
        :description="$t('sections.sell.sellUs.subtitle')"
      />
    </div>
    <div class="gradient-container">
      <div class="gradient-bg-section" />
      <div class="top-substract" />
      <div class="bottom-substract" />
      <div class="steps-section margin-container">
        <StepsPointBig
          :title="$t('sections.sell.sellUs.stepsSection.step1.title')"
          :description="
            $t('sections.sell.sellUs.stepsSection.step1.description')
          "
          icon="sellSteps/sell_us_step1"
          :is-mobile="getBreakpoints.smAndDown"
        />
        <div class="step-separator" />
        <StepsPointBig
          :title="$t('sections.sell.sellUs.stepsSection.step2.title')"
          :description="
            $t('sections.sell.sellUs.stepsSection.step2.description')
          "
          icon="sellSteps/sell_us_step2"
          :is-mobile="getBreakpoints.smAndDown"
        />
        <div class="step-separator" />
        <StepsPointBig
          :title="$t('sections.sell.sellUs.stepsSection.step3.title')"
          :description="
            $t('sections.sell.sellUs.stepsSection.step3.description')
          "
          icon="sellSteps/sell_us_step3"
          :is-mobile="getBreakpoints.smAndDown"
        />
      </div>
    </div>
    <div class="margin-container bottom-section">
      <BaseButton
        large
        type="outlined"
        :label="$t('sections.sell.sellUs.stepsSection.button')"
        @click="$router.push({ name: 'sell-us-process' })"
      />
      <span class="end-phrase">
        {{ $t("sections.sell.sellUs.stepsSection.endPhrase") }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BaseButton, StepsPointBig, SectionHeader } from 'ff-components-lib';

export default {
  name: 'SellUsStepsSection',
  components: {
    StepsPointBig,
    SectionHeader,
    BaseButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
};
</script>
<style lang="scss" scoped>
.steps-section-wrapper {
  padding-bottom: 37px;

  .section-header {
    z-index: 10;
  }

  .steps-section {
    display: flex;
    width: 100%;

    .step-separator {
      height: 4px;
      width: 100%;
      background-color: $primary;
      margin: 55px -65px 0 -65px;
      z-index: 10;
    }
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;

    .base-button {
      z-index: 10;
      background-color: rgba($tertiary, 0.9);
      box-shadow: 0px 4px 30px rgba($primary, 0.4);
    }

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      .base-button {
        background-color: rgba($tertiary, 0.8);
        -webkit-backdrop-filter: blur(48px);
        backdrop-filter: blur(48px);
      }
    }

    .end-phrase {
      z-index: 10;
      font-size: 18px;
      line-height: 28px;
      color: $grey;
    }
  }

  .gradient-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 698px;
    display: flex;
    align-items: center;
    margin-top: -60px;
    margin-bottom: -145px;

    .top-substract {
      background: $tertiary;
      position: absolute;
      top: 0;
      right: -5%;
      width: 110%;
      height: 120px;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
    }

    .bottom-substract {
      background: $tertiary;
      position: absolute;
      bottom: 0;
      right: -5%;
      width: 110%;
      height: 120px;
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
    }

    .gradient-bg-section {
      position: absolute;
      width: 100%;
      height: 698px;
      background: linear-gradient(180deg, rgba($primary, 0.25) 0%, $primary 100%);
      top: 0;
    }
  }
}

@media only screen and (max-width: 900px) {
  .steps-section-wrapper {
    padding-bottom: 56px;

    .bottom-section {
      gap: 24px;
    }

    .steps-section {
      flex-direction: column;

      .step-separator {
        height: 85px;
        width: 5px;
        margin: -3px 0 -5px 52.5px;
      }
    }

    .gradient-container {
      height: 970px;

      .gradient-bg-section {
        height: 970px;
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .steps-section-wrapper {
    .bottom-section {
      .end-phrase {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .steps-section {
      .step-separator {
        width: 3px;
        margin: -3px 0 -5px 35px;
      }
    }

    .gradient-container {
      height: 737px;
      margin-top: -72px;
      margin-bottom: -145px;

      .top-substract {
        height: 120px;
      }

      .bottom-substract {
        height: 120px;
      }

      .gradient-bg-section {
        height: 737px;
        top: 0;
      }
    }
  }
}
</style>
